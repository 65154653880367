// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import { clearLFStorage } from './Services/LFStorage';
import { getAuthData } from './Services/StorageService';
import { logout } from './State/actions/user';

let firstSwInstall = false;

const LogoutAndReset = () => {
  const userId = getAuthData()?.user?.userId || null;
  if (userId) clearLFStorage();
  const pathName = localStorage.getItem('pathName');
  localStorage.clear();
  localStorage.setItem('pathName', pathName);
  clearListCookies();
  if (userId) logout();
  if (!firstSwInstall) {
    setTimeout(() => window.location.reload(true), 500);
  }
  firstSwInstall = false;
};

const clearListCookies = () => {
  const deleteCookie = (cookiename) => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    document.cookie = `${cookiename}=;expires=${d};path=/`;
  };

  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookieName = cookies[i].split('=')[0].trim();
    deleteCookie(cookieName);
  }
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const initializeServiceWorker = (config) => {
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

  if (isLocalhost) {
    checkValidServiceWorker(swUrl, config);
    navigator.serviceWorker.ready.then(() => {
      console.log(
        'This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA',
      );
    });
  } else {
    registerValidSW(swUrl, config);
  }
};

export const register = (config) => {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    const onWindowLoad = () => initializeServiceWorker(config);
    onWindowLoad();
    window.addEventListener('load', onWindowLoad);
  }
};

const registerValidSW = (swUrl, config) => {
  navigator.serviceWorker.register(swUrl, { updateViaCache: 'none' }).then((registration) => {
    setInterval(() => {
      registration.update();
      console.log('Checked for update...');
    }, (1000 * 60) * 2);
    if (registration.waiting || (localStorage.getItem('shouldCleanStorage') === 'true')) {
      console.log(
        'New content is available and will be available upon clicking Yes in version update popup, or clearing browser storage separately',
      );
      if (config && config.onUpdate) {
        config.onUpdate(registration);
      }
    }
    // eslint-disable-next-line no-param-reassign
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            console.log(
              'New content is available and will be available upon clicking Yes in version update popup, or clearing browser storage separately',
            );
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            console.log('SW running for the first time.');
            firstSwInstall = true;
            if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        }
      };
    };
  }).catch((error) => {
    console.error('Error during service worker registration:', error);
  });
};

const checkValidServiceWorker = (swUrl, config) => {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404
        || (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
};

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    }).catch((error) => {
      console.error(error.message);
    });
  }
};
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    console.log('start listening to message for handling clear data event...');
    if (event.data && event.data.type === 'CLEAR_DATA') {
      console.log('initiate clear data.');
      LogoutAndReset();
    }
  });

  // detect controller change and refresh the page
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('controller change event received...');
  });
}
