const slideStyles = {
  slideContainer: (imgUrl) => ({
    backgroundImage: `url("${imgUrl}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  }),
  topLogo: {
    position: 'relative',
    top: '40px',
    left: '40px',
    flex: '0.2',
  },
  cardsContainer: { display: 'flex' },
  sideCardContainer: {
    flex: '0.3',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  sideCardSize: {
    height: '250px',
    width: '200px',
  },
  sideCard: (imgUrl) => ({
    backgroundImage: `linear-gradient(to bottom left, rgba(193, 224, 255, 0.6), rgba(255, 255, 255, 0)), url("${imgUrl}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
    ...slideStyles.sideCardSize,
  }),

  middleCardContainer: {
    flex: '0.4',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '500px',
    overflow: 'hidden',
    borderRadius: '20px',
  },
  middleCardSize: {
    height: '350px',
    width: '250px',
  },
  middleCard: (imgUrl) => ({
    backgroundImage: `url("${imgUrl}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '10px',
    position: 'absolute',
    ...slideStyles.middleCardSize,
  }),

  midCardWrapper: {
    background: 'linear-gradient(to bottom, rgba(193, 224, 255, 0.6), rgba(193, 224, 255, 0))',
    height: '415px',
    width: '310px',
    marginTop: '10px',
    borderRadius: '25px',
  },
  textContainer: {
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '600px',
    marginTop: '-40px',
  },
  mainHeading: {
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '20px',
    color: '#1A1B41',
  },
  content: { fontSize: '14px' },
  leftNavButton: {
    marginRight: '20px',
    cursor: 'pointer',
  },
  rightNavButton: {
    marginLeft: '20px',
    cursor: 'pointer',
  },
  skipButton: {
    width: '150px',
    height: '35px',
    fontSize: '12px',
    marginTop: '20px',
  },
};

if (window.innerHeight < 650) {
  const {
    topLogo,
    sideCardSize,
    middleCardSize,
    middleCardContainer,
    midCardWrapper,
    textContainer,
  } = slideStyles;
  topLogo.top = '20px';
  topLogo.left = '20px';
  sideCardSize.height = '220px';
  sideCardSize.width = '170px';
  middleCardSize.height = '300px';
  middleCardSize.width = '220px';
  middleCardContainer.height = '400px';
  midCardWrapper.height = '360px';
  midCardWrapper.width = '270px';
  textContainer.marginTop = '-20px';
}

export default slideStyles;
