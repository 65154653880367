import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	CHROME_COOKIES_SUPPORT,
	EDGE_COOKIES_SUPPORT,
} from "../Utils/Constants";

const CookiedDisableDlg = () => {
	const [suportUrl, setSupportUrl] = useState(null);
	const [isThirdPartyEnabled, setIsThirdPartyEnable] = useState(null);

	useEffect(() => {
		const frame = document.createElement("iframe");
		frame.id = "3pc";
		frame.src = "https://ashishnfrontm.github.io/cookiesTest/"; //Add your hosted domain url here
		frame.style.display = "none";
		frame.style.position = "fixed";
		document.body.appendChild(frame);

		window.addEventListener(
			"message",
			function listen(event) {
				if (event.data === "3pcSupported" || event.data === "3pcUnsupported") {
					setIsThirdPartyEnable(event.data === "3pcSupported");
					document.body.removeChild(frame);
					window.removeEventListener("message", listen);
				}
			},
			false
		);
	}, []);

	useEffect(() => {
		const detectBrowser = () => {
			const userAgent = navigator.userAgent.toLowerCase();
			if (userAgent.indexOf("chrome") > -1 && userAgent.indexOf("edg") === -1) {
				setSupportUrl(CHROME_COOKIES_SUPPORT);
			} else if (userAgent.indexOf("edg") > -1) {
				setSupportUrl(EDGE_COOKIES_SUPPORT);
			} else {
				setSupportUrl(null);
			}
		};

		detectBrowser();
	}, []);

	return (
		isThirdPartyEnabled !== null && (
			<Dialog
				open={!isThirdPartyEnabled}
				PaperProps={{
					sx: {
						borderRadius: "1.25rem",
						padding: "0.5rem",
						minHeight: "14rem",
						color: "#00295B",
					},
				}}
			>
				<DialogContent
					sx={{ textAlign: "center", borderRadius: "2rem" }}
					className="pb-4"
				>
					<img loading="lazy" src="/img/cookies.svg" alt="cookies" />
					<DialogTitle
						sx={{
							textAlign: "center",
							fontFamily: "Figtree-Regular",
							fontWeight: "700",
							fontSize: "1.5rem",
						}}
					>
						Enable Third-Party Cookies
					</DialogTitle>
					<Typography
						variant="body1"
						sx={{ fontSize: "1rem", fontFamily: "Figtree-Regular" , margin:"0 1.5rem"}}
					>
						To ensure full functionality, please enable third-party cookies.
						Learn more about cookies and privacy settings [<a
							href={`${suportUrl}`}
							target="_blank"
						>here
						</a>
						].
					</Typography>
				</DialogContent>
			</Dialog>
		)
	);
};

export default CookiedDisableDlg;
