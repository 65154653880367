import React, { Component } from "react";
import { PIVOTEL_LANDING, VIKAND_DIRECT_LANDING } from "../../Utils/Constants";
import { withRouter } from 'react-router-dom';

import HomeSlide1 from "./HomeSlide1";
import HomeSlide2 from "./HomeSlide2";
import HomeSlide3 from "./HomeSlide3";
import HomeSlide5 from "./HomeSlide5";
import HomeSlide4 from "./HomeSlide4";
import history from "../../Services/History";


const slides = [HomeSlide1, HomeSlide2, HomeSlide3, HomeSlide5];

class HomeCarousel extends Component {
	constructor(props) {
		super(props);
		this.state = { activeIndex: 0, corporateScreen: false, isCentralisedSlide : false , preFilledEmail:""};
	}

	componentDidMount() {
		const pathArray = ["/vikandconnect", "/pulsar"];

		let isCorporateScreen = false
		if (window.location.pathname) {
			let pathIndex;
			pathIndex = pathArray.findIndex(
				(elem) => elem === window.location.pathname
			);
			if (pathIndex !== -1) {
				if (window.location.pathname === VIKAND_DIRECT_LANDING || PIVOTEL_LANDING) {
					this.setState({ corporateScreen: true });
					isCorporateScreen = true
				}
			}
			if (window.location.pathname === PIVOTEL_LANDING) {
				this.setState({isCentralisedSlide : true})
				slides[3] = HomeSlide4
				slides[4] = HomeSlide5
			}
		}

		if(isCorporateScreen){
			const { location } = this.props;
			const { state } = location || {};
			const {fromResetPassword } = state || {};

			if(fromResetPassword){
				this.setState({activeIndex: slides.length - 1 , fromResetPassword: false})
				history.replace({
					...location,
					state: {
					  ...location.state,
					  fromResetPassword: false
					}
				  });
			}
		}
		

		window.document.onkeydown = (e) => {
			e = e || window.event;
			switch (e.key) {
				case "ArrowLeft":
					this.previous();
					break;
				case "ArrowRight":
					this.next();
					break;
				default:
			}
		};
	}

	preFillEmail = (email) => {
		this.setState({preFilledEmail : email})
	}

	next = () => {
		const nextIndex =
			this.state.activeIndex === slides.length - 1
				? 0
				: this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	};

	previous = () => {
		const nextIndex =
			this.state.activeIndex === 0
				? slides.length - 1
				: this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	};

	goTo = (nextIndex) => {
		this.setState({ activeIndex: nextIndex });
	};

	render() {
		const { activeIndex , isCentralisedSlide} = this.state;

		let Slide = slides[activeIndex]
		return (
			<div
				className="d-flex flex"
				style={{ backgroundColor:"#fff", height: "100%" }}
			>
				<Slide
					corporateScreen={this.state.corporateScreen}
					next={this.next}
					previous={this.previous}
					history={this.props.history}
					activeIndex={activeIndex}
					goTo={this.goTo}
					isCentralised={isCentralisedSlide}
					preFillEmail={this.preFillEmail}
					preFilledEmailValue= {this.state.preFilledEmail}
				/>
			</div>
		);
	}
}

export default withRouter(HomeCarousel);
