import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { BUTTON_BACKGROUND_COLOR_STYLE } from '../../../Utils/ColorPicker';
import {
  PIVOTEL_LOGO,
  PIVOTEL_SLIDER_IMAGE_MAIN,
  SLIDE_LEFT_NAV_BUTTON,
  SLIDE_RIGHT_NAV_BUTTON,
} from '../../../Utils/Constants';

import slideStyles from './centralisedHomeSlideStyles';

const CentralisedHomeSlideMain = (props) => {
  const {
    goTo,
    title,
    description,
    previous,
    next,
    leftCardImg,
    rightCardImg,
    middleCardImg,
  } = props;
  const {
    topLogo,
    slideContainer,
    textContainer,
    mainHeading,
    content,
    cardsContainer,
    sideCardContainer,
    sideCard,
    middleCardContainer,
    midCardWrapper,
    middleCard,
    skipButton,
  } = slideStyles;

  return (
    <div style={slideContainer(PIVOTEL_SLIDER_IMAGE_MAIN)}>
      <div style={topLogo}>
        <img src={PIVOTEL_LOGO} alt='logo' height='40px'/>
      </div>

      <div style={cardsContainer}>
        <div style={sideCardContainer}>
          <Card onClick={previous} style={sideCard(leftCardImg)}/>
        </div>

        <div style={middleCardContainer}>
          <div style={slideStyles.leftNavButton}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              onClick={previous}
              src={SLIDE_LEFT_NAV_BUTTON}
              alt='sideNavImg'
              width='40px'/>
          </div>

          <>
            <div style={midCardWrapper}/>
            <Card style={middleCard(middleCardImg)}/>
          </>

          <div style={slideStyles.rightNavButton}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img onClick={next} src={SLIDE_RIGHT_NAV_BUTTON} alt='sideNavImg' width='40px'/>
          </div>
        </div>

        <div style={sideCardContainer}>
          <Card onClick={next} style={sideCard(rightCardImg)}/>
        </div>
      </div>

      <div style={textContainer}>
        <h3 style={mainHeading}>{title}</h3>
        <p style={content}>{description}</p>
        <button
          type='submit'
          style={{ ...BUTTON_BACKGROUND_COLOR_STYLE, ...skipButton }}
          className='btn btn-open btn-icon'
          onClick={() => goTo(4)}>
          Skip to Login
        </button>
      </div>
    </div>
  );
};

export default CentralisedHomeSlideMain;

CentralisedHomeSlideMain.propTypes = {
  goTo: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  leftCardImg: PropTypes.string.isRequired,
  rightCardImg: PropTypes.string.isRequired,
  middleCardImg: PropTypes.string.isRequired,
};
