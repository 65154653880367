import React from "react";
import HomeSlideRight from "./HomeSlideRight";
import HomeSlideLeft from "./HomeSlideLeft";
import { PIVOTEL_LANDING, PIVOTEL_SLIDER_1, PIVOTEL_SLIDER_2, PIVOTEL_SLIDER_3, VIKAND_DIRECT_LANDING } from "../../Utils/Constants";
import CentralisedHomeSlideMain from "./CentralisedHomeCarousel/CentralisedHomeSlideMain";

const HomeSlide2 = (props) => {

	let corporateScreenComponent = (title, description, imgKey, imgSrc , isCentralised , leftCardImg , rightCardImg) => {
		
		if(isCentralised){
			return (
				<CentralisedHomeSlideMain
					goTo={props.goTo}
					title={title}
					description={description}
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					leftCardImg={leftCardImg}
					rightCardImg={rightCardImg}
					middleCardImg={imgSrc}
				/>
			)
		}else{
			return (
				
				<React.Fragment>
					<HomeSlideLeft
						title={title}
						description={description}
						previous={props.previous}
						next={props.next}
						activeIndex={props.activeIndex}
						goTo={props.goTo}
					/>

					<HomeSlideRight
						imgKey={imgKey}
						image={imgSrc}
					/>
				</React.Fragment>
			)
		}
	}


	if (props.corporateScreen) {
		switch (window.location.pathname) {
			case VIKAND_DIRECT_LANDING:
				const vikandDescription = "Gain access to a full suite of medical services, including but not limited to, crew checkups and mental health support and counseling."
				return corporateScreenComponent("Welfare", vikandDescription, "vikandSlider2", "/img/vikandDirect2.png" , props.isCentralised)

			case PIVOTEL_LANDING:
				const pivotelDescription = "Cruise through your downtime with Pulsar Community's entertainment features. From real-time IP TV including live sports, news, and more, we've got your leisure time covered. Elevate your onboard experience and unwind like never before."
				return corporateScreenComponent("Entertainment", pivotelDescription, "pivotelSlider2", PIVOTEL_SLIDER_2 , props.isCentralised , PIVOTEL_SLIDER_1 , PIVOTEL_SLIDER_3)

			default:
				return null;
		}
	} else
		return (
			<React.Fragment>
				<HomeSlideLeft
					title="Internet Voice Calls"
					description="Call Satellite and landline numbers anywhere the world, and save on costs. Calling satellite numbers has never been this easy and cheap."
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					goTo={props.goTo}
				/>
				<HomeSlideRight
					imgKey="slider2"
					image="/img/internet-voice-calls.svg"
				/>
			</React.Fragment>
		);
};

export default HomeSlide2;
