import React, { Component } from 'react';
import { connect } from 'react-redux';

import Home from '../Components/Home/Home';
import { getFromStorage, putInStorage } from '../Services/StorageService';
import { hydrateUserData } from '../State/actions/user';

class HomeContainer extends Component {
    acceptCookiePolicy = () => {
      putInStorage('cookiePolicyAccepted', true);
      this.setState({ cookiePolicyAccepted: true });
    };

    render() {
      const cookiePolicyAccepted = getFromStorage('cookiePolicyAccepted');
      return (
        <Home
          {...this.props}
          cookiePolicyAccepted={cookiePolicyAccepted}
          acceptCookiePolicy={this.acceptCookiePolicy}/>
      );
    }
}

const mapActionToProps = { hydrateUserData };

const mapDataToProps = (state) => ({ user: state.user.user });

export default connect(mapDataToProps, mapActionToProps)(HomeContainer);
