import commonConfig from "./common.config";

export default {
  ...commonConfig,
  onecarePushkey: 'BL69XuqFgCzKhGWBi46cMHkTRpy_zmzhhapEIwbiKF49fV5pWcv9ZDDku24OXTZUlRzq-YXa8rlImu7-zNQQ3Zg',
  vikandDirectPushkey: 'BOGabZySmAF8blEAGlyt5oR-sG9vHXw69dqkeBpnUoKacIVVs0ky32_TIHuZNdV0UbH-UUSuOeJNmQMi3R6HsMY',
  onshipPushkey: 'BPeocU3uixv-t2UV-Zl6ZFawA0WUxKJDLX3_STDzDkRwlf4lIDIGGJvYXxCM3b6YgdAyvRIz7CHx1bdz5XN0Lys',
  stationonePushKey: 'BFpc4zP91eBEwfRdf4FbDmsO-ie3GSxGwZk-jHlb3wp4X3Yf8LYSDpUF6HHrTDVxyCkzhDyGZU6QyJTCckDv6q8',
  onshipWebPushAppType: "onshipstg",
  vikandWebPushAppType: "vikandstg",
  onecareWebPushAppType: "onecarestg",
  stationoneWebPushAppType: "stationsatcom_uat",
}