import React from "react";
import { PIVOTEL_LANDING, PIVOTEL_SLIDER_2, PIVOTEL_SLIDER_3, PIVOTEL_SLIDER_4, VIKAND_DIRECT_LANDING } from "../../Utils/Constants";
import HomeSlideLeft from "./HomeSlideLeft";
import HomeSlideRight from "./HomeSlideRight";
import CentralisedHomeSlideMain from "./CentralisedHomeCarousel/CentralisedHomeSlideMain";

const HomeSlide3 = (props) => {

	let corporateScreenComponent = (title, description, imgKey, imgSrc , isCentralised , leftCardImg , rightCardImg) => {
		
		if(isCentralised){
			return (
				<CentralisedHomeSlideMain
					goTo={props.goTo}
					title={title}
					description={description}
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					leftCardImg={leftCardImg}
					rightCardImg={rightCardImg}
					middleCardImg={imgSrc}
				/>
			)
		}else{
			return (
				
				<React.Fragment>
					<HomeSlideLeft
						title={title}
						description={description}
						previous={props.previous}
						next={props.next}
						activeIndex={props.activeIndex}
						goTo={props.goTo}
					/>

					<HomeSlideRight
						imgKey={imgKey}
						image={imgSrc}
					/>
				</React.Fragment>
			)
		}
	}

	if (props.corporateScreen) {
		switch (window.location.pathname) {
			case VIKAND_DIRECT_LANDING:
				const vikandDescription = "Experience secure and uninterrupted communication with our team of certified medical professionals onshore to help provide you with the information you need to uphold health and wellness onboard."
				return corporateScreenComponent("Medical Service", vikandDescription, "vikandSlider3", "/img/vikandDirect3.png" , props.isCentralised)

			case PIVOTEL_LANDING:
				const pivotelDescription = "Broaden your horizons and enhance your skills while at sea with Pulsar Community's E-Learning platform. Access a vast library of courses and training materials, empowering you to grow professionally, and achieve regulatory compliance, even in remote waters."
				return corporateScreenComponent("E-Learning", pivotelDescription, "pivotelSlider3", PIVOTEL_SLIDER_3 , props.isCentralised , PIVOTEL_SLIDER_2 , PIVOTEL_SLIDER_4  )

			default:
				return null;
		}
	} else
		return (
			<React.Fragment>
				<HomeSlideLeft
					title="AI Applications"
					description="Artificial Intelligence applications to help with tracking, monitoring and operational assistance. Make your tasks easy and keep on top of them."
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					goTo={props.goTo}
				/>
				<HomeSlideRight imgKey="slider3" image="/img/ai-applications.png" />
			</React.Fragment>
		);
};

export default HomeSlide3;
