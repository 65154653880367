import { getSignupPath } from '../Services/StorageService';

// eslint-disable-next-line max-len
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const pwdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{6,}$/;
export const validateSignInForm = (data) => {
  if (!data.email || !data.password) {
    return 'Email and Password are required.';
  }
  return null;

  // if (!emailRegex.test(data.email)) {
  //   return "Valid Email is required.";
  // }
};

export const validateResetPasswordEmailForm = (data) => {
  if (!data.email) {
    return 'Email is required.';
  }

  if (!emailRegex.test(data.email)) {
    return 'Valid Email is required.';
  }
  return null;
};
export const validatConfirmPasswordResetForm = (data) => {
  const { code } = data;
  const { password } = data;
  const { password2 } = data;
  if (!code) {
    return 'Code is required';
  }

  if (!password) {
    return 'Password field is required';
  }
  const signupPath = getSignupPath();
  if (!signupPath) {
    if (!password2) {
      return 'Confirm password field is required';
    }

    if (password !== password2) {
      return 'Confirm password should be the same';
    }
  }
  return null;
};

export const validateSignUpForm = (data, activationCodeMandatory) => {
  const { email } = data;
  const { password } = data;
  const { password2 } = data;
  const givenName = data.given_name;
  const authCode = !activationCodeMandatory
    ? true
    : !!(data.authCode && data.authCode.length > 0);
  // familyName = data.family_name;

  if (!email || !password || !password2 || !givenName || !authCode) {
    return 'All fields are required.';
  }

  // if (!emailRegex.test(data.email)) {
  //   return "Valid Email is required.";
  // }

  if (!pwdRegex.test(password)) {
    return (
      'Password must be of 6 characters minimum. '
            + 'It should be alphanumeric having at least one upper case, one lower case, a number and a special character.'
    );
  }

  if (password !== password2) {
    return 'Password and confirm password should be the same.';
  }
  return null;
};

export const validateNewChannelForm = (data, ele) => {
  if (ele && ele.find((a) => a.channelName === data.channelName) != null) {
    return 'Group Name already exist.';
  }
  if (!data.channelName || !data.description) {
    return 'Group Name and Description are required.';
  }
  if (!(data.members && data.members.length)) {
    return 'Min 2 participants are required.';
  }
  return null;
};
