import React from "react";
import { PIVOTEL_LANDING, PIVOTEL_LOGIN_IMAGE, PIVOTEL_SLIDER_3, PIVOTEL_SLIDER_4, VIKAND_DIRECT_LANDING } from "../../Utils/Constants";
import HomeSlideLeft from "./HomeSlideLeft";
import HomeSlideRight from "./HomeSlideRight";
import CentralisedHomeSlideMain from "./CentralisedHomeCarousel/CentralisedHomeSlideMain";

const HomeSlide4 = (props) => {

	let corporateScreenComponent = (title, description, imgKey, imgSrc , isCentralised , leftCardImg , rightCardImg) => {
		
		if(isCentralised){
			return (
				<CentralisedHomeSlideMain
					goTo={props.goTo}
					title={title}
					description={description}
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					leftCardImg={leftCardImg}
					rightCardImg={rightCardImg}
					middleCardImg={imgSrc}
				/>
			)
		}else{
			return (
				
				<React.Fragment>
					<HomeSlideLeft
						title={title}
						description={description}
						previous={props.previous}
						next={props.next}
						activeIndex={props.activeIndex}
						goTo={props.goTo}
					/>

					<HomeSlideRight
						imgKey={imgKey}
						image={imgSrc}
					/>
				</React.Fragment>
			)
		}
	}

	if (props.corporateScreen) {
		switch (window.location.pathname) {

			case PIVOTEL_LANDING:
				const pivotelDescription = "Your health is paramount, especially in the open sea. Pulsar Community offers Telehealth services, connecting you to medical professionals for consultations and advice. Ensure your well-being is never compromised, wherever your maritime journey takes you."
				return corporateScreenComponent("Telehealth", pivotelDescription, "pivotelSlider4", PIVOTEL_SLIDER_4 , props.isCentralised  , PIVOTEL_SLIDER_3 ,  PIVOTEL_LOGIN_IMAGE)

			default:
				return null;
		}
	} else
		return (
			<React.Fragment>
				<HomeSlideLeft
					title="AI Applications"
					description="Artificial Intelligence applications to help with tracking, monitoring and operational assistance. Make your tasks easy and keep on top of them."
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					goTo={props.goTo}
				/>
				<HomeSlideRight imgKey="slider3" image="/img/ai-applications.png" />
			</React.Fragment>
		);
};

export default HomeSlide4;
