import React from "react";
import HomeSlideRight from "./HomeSlideRight";
import HomeSlideLeft from "./HomeSlideLeft";
import { PIVOTEL_LANDING, PIVOTEL_LOGIN_IMAGE, PIVOTEL_SLIDER_1, PIVOTEL_SLIDER_2, PIVOTEL_TITLE, VIKAND_DIRECT_LANDING, VIKAND_TITLE } from "../../Utils/Constants";
import CentralisedHomeSlideMain from "./CentralisedHomeCarousel/CentralisedHomeSlideMain";

const HomeSlide1 = (props) => {

	let corporateScreenComponent = (title, description, imgKey, imgSrc , isCentralised , leftCardImg , rightCardImg) => {
		
		if(isCentralised){
			return (
				<CentralisedHomeSlideMain
					goTo={props.goTo}
					title={title}
					description={description}
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					leftCardImg={leftCardImg}
					rightCardImg={rightCardImg}
					middleCardImg={imgSrc}
				/>
			)
		}else{
			return (
				
				<React.Fragment>
					<HomeSlideLeft
						title={title}
						description={description}
						previous={props.previous}
						rightOnly
						next={props.next}
						activeIndex={props.activeIndex}
						goTo={props.goTo}
					/>

					<HomeSlideRight
						imgKey={imgKey}
						image={imgSrc}
					/>
				</React.Fragment>
			)
		}
	}

	if (props.corporateScreen) {
		switch (window.location.pathname) {
			case VIKAND_DIRECT_LANDING:
				window.document.title = VIKAND_TITLE;
				const vikandDescription = "Welcome to VIKAND Connect! The first app of its kind to offer captains a secure and uninterrupted line of communication to medical professionals onshore while at sea."
				return corporateScreenComponent("Communication", vikandDescription, "vikandSlider1", "/img/vikandDirect1.png" , props.isCentralised)

			case PIVOTEL_LANDING:
				window.document.title = PIVOTEL_TITLE
				const pivotelDescription = "Stay connected at sea with Pulsar Community. Enjoy reliable satellite communication, keeping you in touch with those who matter most, no matter where your vessel sails. Seamlessly bridge the distance and share your maritime journey with ease."
				return corporateScreenComponent("Communication", pivotelDescription, "pivotelSlider1", PIVOTEL_SLIDER_1 , props.isCentralised , PIVOTEL_LOGIN_IMAGE , PIVOTEL_SLIDER_2)

			default:
				return null;
		}
	} else
		return (
			<React.Fragment>
				<HomeSlideLeft
					title="Intelligent Collaboration"
					description="Easily connect with your people at sea, mid-air or other remote locations. Chat. Call. and Collaborate with Instant Messaging and AI applications."
					rightOnly
					previous={props.previous}
					next={props.next}
					activeIndex={props.activeIndex}
					goTo={props.goTo}
				/>

				<HomeSlideRight
					imgKey="slider1"
					image={"/img/onboarding-illus-1@3x.png"}
				/>
			</React.Fragment>
		);
};

export default HomeSlide1;
