import React from 'react'
import "./CentralizedForm.css"
import { Card, CardContent } from '@mui/material'
import { PIVOTEL_LOGO, POWERED_BY_WHITE_LABEL } from '../../../Utils/Constants'
import LoginWelcomeText from './LoginWelcomeText'
import LoginContainer from '../../../Containers/LoginContainer'

const CentralizedForm_Landing = (props) => {
  return (
    <div className='centralized-container'>

      <div>
        <Card sx={{ borderRadius: '15px' }}>
          <CardContent>
            <div className='centralized-cardContent'>

              <div className='centralized-corporateLogo'>
                <img src={PIVOTEL_LOGO} height={"50px"} />
              </div>

              <div className='centralized-main-heading'>
                <LoginWelcomeText landingPath={props.landingPath} />
              </div>

                <LoginContainer {...props} />

            </div>
          </CardContent>
        </Card>
      </div>

      <div className='centralized-poweredByLogo'>
        <a
          href="https://frontm.com/"
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src={POWERED_BY_WHITE_LABEL}
            alt="frontm-logo"
            width={"150px"}
            style={{marginRight:"15px"}}
          />
        </a>
      </div>
    </div>
  )
}

export default CentralizedForm_Landing