import { fileToBase64 } from '../FilesService';
import FileService from '../gRPC/Generated/FileService';

import RPC from './RPC';

const { file } = FileService;

class FileServiceClient {
  static uploadLargeFile(fileInfo) {
    return fileToBase64(fileInfo.file).then((base64Str) => {
      const obj = {
        ...fileInfo,
        file: base64Str,
      };
      console.log('uploadLargeFile', obj);
      return RPC.rpcCall(
        '/file.FileService/UploadFile',
        obj,
        file.GetFileUploadResponse,
        (request) => file.GetFileUploadInput.encode(request).finish(),
        file.GetFileUploadResponse.decode,
      );
    });
  }

  static uploadProfilePhoto(userId, fileData, selectedDomain) {
    return this.uploadFile('profile-pics', userId, fileData, selectedDomain);
  }

  static uploadFile(folder, fileName, fileData, selectedDomain) {
    return fileToBase64(fileData).then((base64Str) => {
      const payloadData = {
        appType: selectedDomain,
        base64File: base64Str,
        domain: selectedDomain,
        fileName,
        fileScope: null,
        generateThumbnail: true,
        platform: 'web',
        scopeId: folder,
        selectedDomain,
        userDomain: selectedDomain,
      };
      return RPC.rpcCall(
        '/file.FileService/UploadFileBase64',	
        payloadData,
        file.GetFileUploadResponse,
        (request) => file.GetFileUploadInput.encode(request).finish(),
        file.GetFileUploadResponse.decode,
      );
    });
  }
}

export default FileServiceClient;
