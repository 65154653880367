import devConfig from './envConfig/dev.config';
import prodConfig from './envConfig/prod.config';
import stageConfig from './envConfig/stage.config';
import stageOldConfig from './envConfig/stage.old.config';

const env = process.env.REACT_APP_ENV || 'development';

console.log("Environment inside configjs", env);

const config = {
  development: devConfig,
  production: prodConfig,
  stage: stageConfig,
  stage_old: stageOldConfig 
};

export default config[env];