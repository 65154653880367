export default {
  envName: 'stage',
  baseURL: 'https://stageapi.frontm.ai',
  filesAPI: 'https://gwuat.frontm.ai/proxy/resource',
  contentURL: 'https://gwuat.frontm.ai/proxy/botLogos/',
  botFilesAPI: 'https://gwuat.frontm.ai/proxy/resource/botfile?path=',
  gmapsApiKey: 'AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k',
  paymentGatewayKey: 'pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J',
  soundURL: 'https://gwuat.frontm.ai/proxy/sounds/',
  mapboxApiAccessToken: 'pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A',
  // gRPCURL: "https://prod2.frontm.com:8080", prev -grpc url
  gRPCURL: 'https://gwuat.frontm.ai', // new grpc url
  webertcSignalServer: 'gwuat.frontm.ai',
  mapURL: 'https://maps.frontm.ai/styles/klokantech-basic/style.json',
  gnsDomain: 'voyagervoice',
  aageHempelDomain: 'aagehempelEndUser',
  onshipDomain: 'onshipEndUser',
  sipURI: 'frontmsecureweb@pstn-prod.frontm.ai',
  sipPassword: 'frontm@123^%',
  sipWebsocket: 'wss://pstn-prod.frontm.ai:8089/ws',
  pingServerDataFile: 'prodServerData.json',
  licenseCodeVerificationAPI: 'https://34zivd2j1l.execute-api.us-east-1.amazonaws.com/stage',
  licenseCodeVerificationAPI_Token: 'ez722l6OqW3m69FTiAkyktnBJjDqwO4aSjeNUxRf',
};
