import CloseIcon from '@mui/icons-material/Close';
import {
  Box, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { clearLFStorage } from '../Services/LFStorage';
import { getAuthData } from '../Services/StorageService';
import { logout } from '../State/actions/user';

const UpdateAppVersionDlg = ({ open: externalOpen, handleConfirm, registrationObj }) => {
  const [internalOpen, setInternalOpen] = useState(externalOpen);
  const [progress, setProgress] = useState(100);
  const [countdown, setCountdown] = useState(120); // 120 seconds for 2 minutes

  const LogoutAndReset = () => {
    const userId = getAuthData()?.user?.userId || null;
    if (userId) clearLFStorage();
    const pathName = localStorage.getItem('pathName');
    localStorage.clear();
    localStorage.setItem('pathName', pathName);
    clearListCookies();
    if (userId) logout();
    setTimeout(() => window.location.reload(true), 500);
  };

  const clearListCookies = () => {
    const deleteCookie = (cookiename) => {
      const d = new Date();
      d.setDate(d.getDate() - 1);
      document.cookie = `${cookiename}=;expires=${d};path=/`;
    };

    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookieName = cookies[i].split('=')[0].trim();
      deleteCookie(cookieName);
    }
  };

  const handleConfirmationTasks = () => {
    handleConfirm();
    if (!registrationObj.waiting) {
      console.log('initiate clear data.');
      LogoutAndReset();
    }
  };

  useEffect(() => {
    setInternalOpen(externalOpen);
  }, [externalOpen]);

  useEffect(() => {
    if (!internalOpen) return; // Only start the timer when the dialog is open

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          handleConfirmationTasks(); // Automatically click "Yes" when time runs out
          return 0;
        }
        return prevCountdown - 1;
      });
      setProgress((prevProgress) => (prevProgress - 100 / 120));
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => { clearInterval(timer); };
  }, [internalOpen, handleConfirm]);

  const handleClose = () => {
    setInternalOpen(false);
    setCountdown(120);
    setProgress(100);
  };

  return (
    <Dialog
      open={internalOpen}
      sx={{ '& .MuiPaper-root': { borderRadius: '15px' } }}>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        size='small'
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
          color: (theme) => theme.palette.common.black,
          '& svg': { // Targeting the svg element inside IconButton
            width: 16, // Set width to 16px
            height: 16, // Optionally, set height to maintain aspect ratio
          },
        }}>
        <CloseIcon/>
      </IconButton>
      <Box sx={{ padding: '35px' }}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          New Version Available
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <DialogContentText>
            New app version is available. Click &apos;Yes&apos; to clear app storage and reload for latest. If no action
            taken, new version would auto reload in two minutes.
          </DialogContentText>
          <Box sx={{ position: 'relative', display: 'inline-flex', top: 10 }}>
            <CircularProgress variant='determinate' value={100} size={70} style={{ color: '#eee' }} />
            <CircularProgress variant='determinate' value={progress} size={70} style={{ position: 'absolute', left: 0 }} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Box>{`${Math.round(countdown)}s`}</Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={handleClose}
            style={{
              color: '#727484', border: '1.2px solid #D6D8E5', borderRadius: 6, textTransform: 'none', width: 165, height: 45,
            }}
            disableRipple>
            Cancel
          </Button>
          <Button
            onClick={() => { handleClose(); handleConfirmationTasks(); }}
            style={{
              color: 'white',
              backgroundColor: '#376AFA',
              border: '1.2px solid #376AFA',
              borderRadius: 6,
              textTransform: 'none',
              width: 165,
              height: 45,
            }}
            disableRipple
            autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

UpdateAppVersionDlg.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default UpdateAppVersionDlg;
